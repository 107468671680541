<template>
    <div class="card p-5">
        <HeaderPanel :tittle="'Estrutura'" :tittleCaminho="'Dashboard'" :loading="loading" nomeTelaDoManual="dashboard-estrutura"/>
        <div class="filter-cnt" :class="{ 'disabled': loading }">
            <div>
                <label for="txtFilterCliente" class="mb-1">Pesquisar por</label>
                <Dropdown
                    v-model="tipoFiltro"
                    :options="tipoFiltroOptions"
                    dataKey="id"
                    optionLabel="label"
                    optionValue="value"
                    placeholder="Selecione..."
                    class="w-full"
                    @change="limparTipoNaoUtilizado"
                />
            </div>
            <div>
                <template v-if="tipoFiltro == 1">
                    <label for="txtFilterCliente" class="mb-1">Cliente</label>
                    <MultiSelectCliente
                        v-model="customersSelectedIds"
                        dataKey="id"
                        optionLabel="name"
                        class="w-full"
                        placeholder="Selecione os clientes..."
                    />
                </template>
                <template v-else-if="tipoFiltro == 2">
                    <label for="txtFilterCliente" class="mb-1">Grupo</label>
                    <MultiSelectGrupos v-model="groupSelectedIds" dataKey="id" class="w-full" placeholder="Selecione os grupos..." />
                </template>
                <template v-else-if="tipoFiltro == 3">
                    <label for="txtFilterCliente" class="mb-1">Unidade</label>
                    <MultiSelectUnidadePorUsuario
                        v-model="customerBranchesSelectedIds"
                        dataKey="id"
                        class="w-full"
                        placeholder="Selecione as unidades..."
                    />
                </template>
            </div>
            <div>
                <div class="flex justify-content-end flex-row h-full">
                    <div class="flex align-items-end">
                        <Button
                            type="button"
                            label="Recarregar"
                            class="h-3rem"
                            @click="loadAllComponentes"
                            icon="pi pi-sync"
                            loadingIcon="pi pi-sync pi-spin"
                            :loading="loading"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="multi-panel-cnt">
                <MultiQuantityPanel ref="multiQuantityPanel" :customerIds="customerIds" :grupoIds="grupoIds" :customerBranchIds="customerBranchIds" />
            </div>

            <div class="second-cnt">
                <DonutChart
                    titulo="Funcionários por status"
                    :customerIds="customerIds"
                    :grupoIds="grupoIds"
                    :customerBranchIds="customerBranchIds"
                    ref="funcionariosPorStatus"
                    legenda-personalizada
                    :labelTotal="'Total de funcionários'"
                    :comFiltro="false"
                />

                <ChartLinhaSimples
                    titulo="Inclusões de cargo com aplicação de risco"
                    :customerIds="customerIds"
                    :grupoIds="grupoIds"
                    :customerBranchIds="customerBranchIds"
                    ref="inclusoesCargoAplicacaoRisco"
                >
                    <Filtro />
                </ChartLinhaSimples>
            </div>
        </div>
    </div>
</template>

<script>
import HeaderPanel from '../../relatorios-e-indicadores/components/HeaderPanel.vue';
import MultiSelectCliente from '../../clientes/components/MultiSelectCliente.vue';
import MultiSelectUnidadePorUsuario from '../../unidades/components/MultiSelectUnidadePorUsuario.vue';
import { getCurrentCompany, getCurrentCustomers } from '@/services/store';
import MultiQuantityPanel from '../components/MultiQuantityPanel/index.vue';
import DonutChart from '../components/DonutChart.vue';
import ChartLinhaSimples from '../components/ChartLinhaSimples.vue';
import Filtro from '../components/Filtro.vue';
import MultiSelectGrupos from '../../grupos/components/MultiSelectGrupos.vue';

export default {
    components: {
        MultiQuantityPanel,
        HeaderPanel,
        MultiSelectCliente,
        MultiSelectGrupos,
        MultiSelectUnidadePorUsuario,
        DonutChart,
        ChartLinhaSimples,
        Filtro
    },
    data() {
        return {
            customerIds: [],
            customerBranchesSelectedIds: [],
            usersOptions: [],
            tipoFiltro: 1,
            groupSelectedIds: [],
            grupoIds: [],
            customerBranchIds: [],
            customersSelectedIds: [],
            tipoFiltroOptions: [
                { label: 'Clientes', value: 1 },
                { label: 'Grupos', value: 2 },
                { label: 'Unidades', value: 3 }
            ],
            loading: false
        };
    },
    computed: {
        tenant() {
            return getCurrentCompany();
        },
        currentCustomer() {
            return getCurrentCustomers();
        }
    },
    async mounted() {
        await this.loadAllComponentes();
    },
    watch: {
        currentCustomer() {
            this.customerIds = this.currentCustomer;
            setTimeout(() => {
                this.loadAllComponentes();
            }, 100);
        },
        customersSelectedIds() {
            this.customerIds = this.customersSelectedIds.map((p) => p.id) ?? [];
            setTimeout(() => {
                this.loadAllComponentes();
            }, 100);
        },
        groupSelectedIds() {
            this.grupoIds = this.groupSelectedIds.map((p) => p.id) ?? [];
            setTimeout(() => {
                this.loadAllComponentes();
            }, 100);
        },
        customerBranchesSelectedIds() {
            this.customerBranchIds = this.customerBranchesSelectedIds.map((p) => p.id) ?? [];
            setTimeout(() => {
                this.loadAllComponentes();
            }, 100);
        }
    },
    methods: {
        limparTipoNaoUtilizado() {
            if (this.tipoFiltro == 1) {
                this.groupSelectedIds = [];
                this.customerBranchesSelectedIds = [];
            } else if (this.tipoFiltro == 2) {
                this.customersSelectedIds = [];
                this.customerBranchesSelectedIds = [];
            } else {
                this.groupSelectedIds = [];
                this.customersSelectedIds = [];
            }
        },
        async loadAllComponentes() {
            this.loading = true;
            try {
                await Promise.all([
                    this.$refs.multiQuantityPanel.queryData('/indicadores/estrutura/por-clientes/unidades-setores-cargos-funcionarios-usuarios'),
                    this.$refs.funcionariosPorStatus.queryData('/indicadores/estrutura/por-clientes/funcionarios-por-situation'),
                    this.$refs.inclusoesCargoAplicacaoRisco.queryData('/indicadores/estrutura/por-clientes/cargos-com-aplicacao-de-risco')
                ]);
                this.loading = false;
            } catch (error) {
                this.$toast.add({
                    severity: 'info',
                    summary: 'Ops!',
                    detail: 'Tivemos algum problema ao carregar os gráficos, por favor clique em recarregar!',
                    life: 3000
                });
                this.loading = false;
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.filter-cnt {
    display: grid;
    grid-template-columns: calc(40% - 10px) calc(40% - 14px) calc(20% - 16px);
    gap: 20px;

    @media (max-width: 991px) {
        grid-template-columns: 1fr;
    }
}

.multi-panel-cnt {
    display: grid;
    grid-template-columns: calc(20% - 15px) calc(20% - 15px) calc(20% - 17px) calc(20% - 17px) calc(20% - 16px);
    gap: 20px;
    margin: 20px 0;

    @media (max-width: 991px) {
        grid-template-columns: 1fr;
    }
}

.second-cnt {
    display: grid;
    grid-template-columns: calc(40% - 10px) calc(60% - 10px);
    gap: 20px;

    @media (max-width: 991px) {
        grid-template-columns: 1fr;
    }
}

::-webkit-scrollbar-track {
    background-color: #f4f4f436;
    border-radius: 15px;
    margin-left: 5px;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: #f4f4f4;
    margin-left: 5px;
}

::-webkit-scrollbar-thumb {
    background: #ced4da;
    border-radius: 15px;
    margin-left: 5px;
}
.disabled {
    pointer-events: none; 
    opacity: 0.5; 
}
</style>
